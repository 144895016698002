import api from '../library/axios';
import RSA from '../library/rsa-encrypt';

export default {
  getList(page, search, sort_column, sort_type) {
    return api({
      method: 'get',
      url: '/api/users',
      params: {
        page,
        search,
        sort_column,
        sort_type,
      },
    });
  },
  createUser({
    name,
    phone_number,
    email,
    password,
    is_pic = false,
    role_id,
    workspace_ids = [],
  }) {
    return api({
      method: 'post',
      url: '/api/users',
      data: {
        name,
        phone_number,
        email,
        password: RSA.encrypt(password, 'base64'),
        is_pic,
        role_id,
        workspace_ids,
      },
    });
  },
  update(user_id, {
    name,
    email,
    is_pic,
    password,
    role_id,
    workspace_ids = [],
  }) {
    return api({
      method: 'patch',
      url: `/api/users/${user_id}`,
      data: {
        name,
        email,
        is_pic,
        password,
        role_id,
        workspace_ids,
      },
    });
  },
  delete(user_id) {
    return api({
      method: 'delete',
      url: `/api/users/${user_id}`,
    });
  },
  getRoles() {
    return api({
      url: '/api/roles',
    });
  },
};
