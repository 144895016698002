<template>
  <b-modal v-model="showModal" title="Edit Contact" hide-footer @hide="onHide">
    <b-form @submit.prevent="updateContact" @reset="onHide">
      <b-form-group :label="$t('conversations.contact.title.name')">
        <b-form-input
          v-model="v$.form.name.$model"
          type="text"
          :placeholder="$t('conversations.contact.placeholder.name')"
          required />
        <b-form-invalid-feedback :state="!v$.form.name.$invalid" v-if="v$.form.name.$errors[0]">
          {{ $t('validation.' + v$.form.name.$errors[0].$validator) }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group :label="$t('conversations.contact.title.dedicated_agent')">
        <el-select v-model="form.dedicated_agent_user_id" class="w-100" :placeholder="$t('conversations.contact.placeholder.dedicated_agent')">
          <el-option :label="item.user_name + ' (' + item.user_email + ')'" v-for="item in agentList" :value="item.user_id" :key="item.user_id">{{ item.user_name }} ({{ item.user_email }})</el-option>
        </el-select>
        <small>Every conversation started from this user will assigned to <template v-if="selectedAgentName"><b>{{ selectedAgentName }}</b></template><template v-else>the dedicated agent</template>.</small>
      </b-form-group>
      <div class="d-flex flex-row-reverse">
        <el-button @click="updateContact" :loading="loading.update_contact" class="btn ml-2 btn-primary">{{ $t('general.submit') }}</el-button>
        <b-button @click="onHide" type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
      </div>
    </b-form>
  </b-modal>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import contactApi from '@/api/contacts';
import popupErrorMessages from '@/library/popup-error-messages';
import agentsAPI from '../../../api/agents';

export default {
  name: 'ConvoEditContact',
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
      },
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectedContact: {
      type: Object,
      default() { return {}; },
    },
    conversation: {
      type: Object,
      default() { return {}; },
    },
  },
  data() {
    return {
      loading: {
        update_contact: false,
      },
      form: {
        name: '',
        dedicated_agent_user_id: '',
      },
      showModal: false,
      agentList: [],
      timeoutSearchAgent: null,
    };
  },
  watch: {
    show() {
      this.showModal = this.show;
      if (this.show) {
        this.form = this.selectedContact;
        this.getAgentList();
      }
    },
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    selectedAgentName() {
      const f = this.agentList.find((v) => v.user_id === this.form.dedicated_agent_user_id);
      if (f) {
        return f.user.name;
      }
      return null;
    },
  },
  methods: {
    async updateContact() {
      this.v$.form.$touch();
      if (this.v$.form.$error) return;
      this.loading.update_contact = true;
      await contactApi.update({
        workspace_id: this.activeWorkspace._id,
        id: this.form.id,
        data: {
          display_name: this.form.name,
          dedicated_agent_user_id: this.form.dedicated_agent_user_id,
        },
      }).then(async (response) => {
        await popupErrorMessages(response);
        this.$message({
          type: 'success',
          message: this.$t('general.success'),
        });
        this.showModal = false;
        this.onHide();
        this.$parent.showDetailChat({ conversation_id: this.conversation.id, reload_history: false });
      }).catch(() => {});
      this.loading.update_contact = false;
    },
    handlerSearchUser(value) {
      clearTimeout(this.timeoutSearchAgent);
      this.timeoutSearchAgent = setTimeout(() => {
        this.getAgentList(value);
      }, 1000);
    },
    async getAgentList(search = '') {
      this.loading.agent = true;
      await agentsAPI.getList(this.activeWorkspace._id, 1, search)
        .then(async (res) => {
          await popupErrorMessages(res);
          this.agentList = res.data.rows.map((v) => {
            v.user_name = v.user.name;
            v.user_email = v.user.email;
            return v;
          });
        })
        .catch(() => {});
      this.loading.agent = false;
    },
    onHide() {
      this.$emit('onHide');
    },
  },
};
</script>
