<template>
  <!-- preview call progress -->
  <div class="modal-call">
    <b-modal v-model="showModalMaximizeCall" @hide="onHide"
      centered
      hide-footer
      size="sm"
      header-bg-variant="dark"
      header-text-variant="light"
      body-bg-variant="dark"
      body-text-variant="light"
      footer-bg-variant="dark"
      footer-text-variant="light"
      :hide-header="true"
      :hide-header-close="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      modal-class="modal-call"
      >
      <div class="align-items-center border-header-call mt-1">
        <div class="flex-container-call">
          <div class="text-center">
            <p class="h5 font-weight-bold"> {{ phone_call.status }} </p>
          </div>
          <a class="text-light cursor-pointer mb-2" @click="minimizeCall()">
            <i class="mdi mdi-arrow-collapse mdi-18px"></i>
          </a>
        </div>
      </div>
      <div>
        <div class="d-block text-center mt-4">
          <div class="rounded-full" v-html="this.identicons(phone_call.conversation_id)"/>
          <h5 class="mt-3">{{ phone_call.name }}</h5>
          <p>{{ phone_call.phone_number }}</p>
          <h5 class="mt-2" v-if="call.duration !== 0">{{ call.duration }}</h5>
        </div>
        <div class="mb-4 text-center" style="margin-top: 8rem !important" v-if="this.call_session.originator === 'local' || call.duration != 0">
          <!-- <el-button circle class="btn btn-secondary btn-icon btn-lg right-px" @click="handleVolumePhoneCall">
            <i :class="phone_call.volume"></i>
          </el-button> -->
          <el-button circle class="btn btn-danger btn-icon btn-lg" @click="handleEndPhoneCall">
            <i class="mdi mdi-phone-hangup mdi-24px"></i>
          </el-button>
          <el-button circle class="btn btn-secondary btn-icon btn-lg" @click="handleMicPhoneCall">
            <i :class="phone_call.mic"></i>
          </el-button>
        </div>
        <div class="mb-4 text-center" style="margin-top: 8rem !important" v-else-if="this.call_session.originator === 'remote'">
          <el-button circle class="btn btn-danger btn-icon btn-lg" @click="handleEndPhoneCall">
            <i class="mdi mdi-phone-hangup mdi-24px"></i>
          </el-button>
          <el-button circle class="btn btn-success btn-icon btn-lg" @click="handleAnswerIncomingCall">
            <i class="mdi mdi-phone mdi-24px"></i>
          </el-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import moment from 'moment';
// import _ from 'lodash';
// import openSocket from 'socket.io-client';
// import JsSIP from 'jssip';
import { toSvg } from 'jdenticon';
// import NotifSound from '../../../assets/sound/Block.mp3';
// import config from '@/config';
// import conversationsAPI from '../../../api/conversations';
// import popupErrorMessages from '../../../library/popup-error-messages';

// const socket = openSocket(config.api.chatUrl, {
//   transports: ['websocket'],
//   upgrade: false,
//   reconnection: true,
//   reconnectionAttempts: 1000,
//   reconnectionDelay: 1000,
// });
// prox-quiros.mariworks.id
// process.env.VUE_APP_SIP_DOMAIN
// const connect_sip = new JsSIP.WebSocketInterface('wss://process.env.VUE_APP_SIP_DOMAIN:8089/ws');
// // connect_sip.via_transport = 'tcp';
// const configuration = {
//   sockets: [connect_sip],
//   uri: 'sip:4007@process.env.VUE_APP_SIP_DOMAIN',
//   password: 'WAPPIN2024',
//   display_name: 'Agent AAA',
//   pcConfig: {
//     rtcpMuxPolicy: 'negotiate',
//     iceServers: [{
//       urls: [
//         'stun:stun.l.google.com:19302',
//       ],
//     }],
//   },
// };
// const socket_sip = new JsSIP.UA(configuration);

export default {
  name: 'PreviewMaximizeCall',
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    call_session: {
      type: Object,
      default() {
        return {};
      },
    },
    phone_call: {
      type: Object,
      default() {
        return {};
      },
    },
    call: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      showModalMaximizeCall: false,
    };
  },
  methods: {
    identicons(value) {
      return toSvg(value, '75');
    },
    onHide() {
      this.$emit('onHide');
    },
    handleMicPhoneCall() {
      const session_muted = this.call_session.session.isMuted();
      if (session_muted.audio) {
        this.phone_call.mic = 'mdi mdi-microphone mdi-24px';
        this.call_session.session.mute({
          audio: true,
          video: false,
        });
      } else {
        this.phone_call.mic = 'mdi mdi-microphone-off mdi-24px';
        this.call_session.session.unmute({
          audio: false,
          video: false,
        });
      }
    },
    handleVolumePhoneCall() {
      if (this.phone_call.volume === 'mdi mdi-volume-low mdi-24px') {
        this.phone_call.volume = 'mdi mdi-volume-source mdi-24px';
      } else {
        this.phone_call.volume = 'mdi mdi-volume-low mdi-24px';
      }
    },
    handleAnswerIncomingCall() {
      this.$parent.handleAnswerIncomingCall();
    },
    handleEndPhoneCall() {
      this.$parent.handleEndPhoneCall();
    },
    minimizeCall() {
      this.$parent.handleMinimizePhoneCall();
    },
  },
  watch: {
    show() {
      this.showModalMaximizeCall = this.show;
    },
  },
};
</script>

<style>
.modal-call .bg-dark {
  background-color: #101623 !important;
}
.modal-call .modal-content {
  border: 2px solid #474747;
  background-color: #101623;
  border-radius: 1.135rem;
}
.modal-header {
  /* border-bottom: 1px solid #474747; */
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.modal-body {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.border-header-call {
  border-bottom: 2px solid #474747 !important;
}

.flex-container-call {
  display: flex;
  /* align-content: space-between; */
  align-items: baseline;
  justify-content: space-between;
  margin-inline-end: auto;
}
</style>
