import api from '../library/axios';
import config from '../config';

export default {
  get_steps({ workspace_id }) {
    return api({
      method: 'get',
      baseURL: config.api.leadsUrl,
      url: `/api/settings/steps/${workspace_id}/list`,
    });
  },
  update_steps({ workspace_id, data }) {
    return api({
      method: 'post',
      baseURL: config.api.leadsUrl,
      url: `/api/settings/steps/${workspace_id}/update`,
      data,
    });
  },
  delete_step({ workspace_id, key, form }) {
    return api({
      method: 'patch',
      baseURL: config.api.leadsUrl,
      url: `/api/settings/steps/${workspace_id}/delete`,
      data: {
        step_key: key,
        form,
      },
    });
  },
  get_leads_all({ workspace_id, step_key }) {
    return api({
      method: 'get',
      baseURL: config.api.leadsUrl,
      url: `/api/settings/steps/${workspace_id}/leads`,
      params: {
        step_key,
      },
    });
  },
  searchContacts({ workspace_id, keyword }) {
    return api({
      method: 'get',
      baseURL: config.api.leadsUrl,
      url: `/api/references/contact/${workspace_id}`,
      params: {
        keyword,
      },
    });
  },
  searchUsers({ keyword }) {
    return api({
      method: 'get',
      baseURL: config.api.leadsUrl,
      url: '/api/references/users/sales',
      params: {
        keyword,
      },
    });
  },
  get_leads({
    workspace_id,
    step_key,
    offset,
    search,
    filter_type,
  }) {
    return api({
      method: 'get',
      baseURL: config.api.leadsUrl,
      url: `/api/manage/board/${workspace_id}`,
      params: {
        step_key,
        offset,
        search,
        filter_type,
      },
    });
  },
  get_leads_detail({ workspace_id, id }) {
    return api({
      method: 'get',
      baseURL: config.api.leadsUrl,
      url: `/api/manage/board/${workspace_id}/${id}`,
    });
  },
  get_logs({ workspace_id, id }) {
    return api({
      method: 'get',
      baseURL: config.api.leadsUrl,
      url: `/api/manage/logs/${workspace_id}/${id}`,
    });
  },
  create_leads({ workspace_id, data }) {
    return api({
      method: 'post',
      baseURL: config.api.leadsUrl,
      url: `/api/manage/board/${workspace_id}`,
      data,
    });
  },
  update_leads({ workspace_id, id, data }) {
    return api({
      method: 'patch',
      baseURL: config.api.leadsUrl,
      url: `/api/manage/board/${workspace_id}/${id}`,
      data,
    });
  },
  update_step_leads({ workspace_id, id, data }) {
    return api({
      method: 'patch',
      baseURL: config.api.leadsUrl,
      url: `/api/manage/board/${workspace_id}/${id}/step`,
      data,
    });
  },
  delete_leads({ workspace_id, id }) {
    return api({
      method: 'delete',
      baseURL: config.api.leadsUrl,
      url: `/api/manage/board/${workspace_id}/${id}`,
    });
  },
  complete_leads({ workspace_id, id, data }) {
    return api({
      method: 'patch',
      baseURL: config.api.leadsUrl,
      url: `/api/manage/board/${workspace_id}/${id}/complete`,
      data,
    });
  },
  get_completed_leads(workspace_id, data) {
    return api({
      method: 'get',
      baseURL: config.api.leadsUrl,
      url: `/api/history/leads/${workspace_id}`,
      params: data,
    });
  },
  download(workspace_id, data) {
    return api({
      method: 'get',
      baseURL: config.api.leadsUrl,
      url: `/api/history/leads/${workspace_id}/download`,
      params: data,
    });
  },
};
