<template>
  <nav class="navbar">
    <a href="#" class="sidebar-toggler" @click.prevent="$emit('toggle-sidebar')" v-if="routeMeta.layout !== 'topOnly'">
      <FeatherIcon type="menu" />
    </a>
    <div class="navbar-content align-items-center">
      <p class="h5 font-weight-bold" v-if="routeMeta.layout !== 'topOnly'">
        {{ title }}
      </p>
      <a href="#" class="sidebar-brand" v-else>
        <img :src="'/img/'+(env('VUE_APP_COBRAND') || 'wappin')+'-logo.png'" height="30" />
      </a>
      <ul class="navbar-nav">
        <li class="nav-item" v-if="routeMeta.layout !== 'topOnly'">
          <a class="nav-link font-weight-bold" href="#" @click.prevent="selectWorkspace()">
            {{ activeWorkspace.name }}
          </a>
        </li>
        <li class="nav-item dropdown nav-profile" v-if="routeMeta.layout !== 'topOnly'">
          <a class="nav-link dropdown-toggle"
            href="#"
            id="profileDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            @click.prevent="topupHistories()"
            aria-expanded="false">
            (Rp. {{ workspaceBalance }})
          </a>
          <div class="dropdown-menu" aria-labelledby="profileDropdown">
            <div class="dropdown-body mt-2">
              <ul class="profile-nav p-0">
                <li class="nav-item mb-2">
                  <a href="javascript:;" class="nav-link d-flex justify-content-between w-100">
                    <small>Balance</small>
                    <el-tag size="mini" type="success">Rp. {{ workspaceBalance }}</el-tag>
                  </a>
                </li>
                <li class="nav-item mb-2" v-if="workspaceFreeQuota.free_credit">
                  <a href="javascript:;" class="nav-link d-flex justify-content-between w-100">
                    <small>Free Credit</small>
                    <el-tag size="mini" type="success">Rp. {{ workspaceFreeQuota.free_credit.toLocaleString('id-ID') || 0 }}</el-tag>
                  </a>
                </li>
                <li class="nav-item mb-2" v-if="workspaceFreeQuota.whatsapp">
                  <a href="javascript:;" class="nav-link d-flex justify-content-between w-100">
                    <small>Free WhatsApp Quota</small>
                    <el-tag size="mini" type="success">{{ workspaceFreeQuota.whatsapp || 0 }}</el-tag>
                  </a>
                </li>
                <li class="nav-item mb-2" v-if="workspaceFreeQuota.whatsapp_bi">
                  <a href="javascript:;" class="nav-link d-flex justify-content-between w-100">
                    <small>Free WhatsApp BI Quota</small>
                    <el-tag size="mini" type="success">{{ workspaceFreeQuota.whatsapp_bi || 0 }}</el-tag>
                  </a>
                </li>
                <li class="nav-item mb-2" v-if="workspaceFreeQuota.whatsapp_ui">
                  <a href="javascript:;" class="nav-link d-flex justify-content-between w-100">
                    <small>Free WhatsApp UI Quota</small>
                    <el-tag size="mini" type="success">{{ workspaceFreeQuota.whatsapp_ui || 0 }}</el-tag>
                  </a>
                </li>
                <li class="nav-item mb-2" v-if="workspaceFreeQuota.whatsapp_mkt">
                  <a href="javascript:;" class="nav-link d-flex justify-content-between w-100">
                    <small>Free WhatsApp Marketing Quota</small>
                    <el-tag size="mini" type="success">{{ workspaceFreeQuota.whatsapp_mkt || 0 }}</el-tag>
                  </a>
                </li>
                <li class="nav-item mb-2" v-if="workspaceFreeQuota.whatsapp_auth">
                  <a href="javascript:;" class="nav-link d-flex justify-content-between w-100">
                    <small>Free WhatsApp Authentication Quota</small>
                    <el-tag size="mini" type="success">{{ workspaceFreeQuota.whatsapp_auth || 0 }}</el-tag>
                  </a>
                </li>
                <li class="nav-item mb-2" v-if="workspaceFreeQuota.whatsapp_util">
                  <a href="javascript:;" class="nav-link d-flex justify-content-between w-100">
                    <small>Free WhatsApp Utility Quota</small>
                    <el-tag size="mini" type="success">{{ workspaceFreeQuota.whatsapp_util || 0 }}</el-tag>
                  </a>
                </li>
                <li class="nav-item mb-2" v-if="workspaceFreeQuota.whatsapp_svc">
                  <a href="javascript:;" class="nav-link d-flex justify-content-between w-100">
                    <small>Free WhatsApp Service Quota</small>
                    <el-tag size="mini" type="success">{{ workspaceFreeQuota.whatsapp_svc || 0 }}</el-tag>
                  </a>
                </li>
              </ul>
            </div>
            <div class="dropdown-header align-items-center">
              <a class="mb-3 w-100 btn btn-primary" @click="handlerBtnTopup">
                <font-awesome icon="money-bill-wave"/> Topup
              </a><br/>
              <a class="mb-3 w-100 btn btn-secondary" @click="$router.push({ name: 'DepositHistories' })">
                <font-awesome icon="history"/> Deposit Histories
              </a>
            </div>
            <div class="mt-2"><strong>Last Topup Histories</strong></div>
            <div class="dropdown-body mt-2" v-loading="loading.balance_histories">
              <template v-if="topup_histories_list.length > 0">
                <ul class="profile-nav p-0">
                  <li class="nav-item mb-2" v-for="item in topup_histories_list" :key="item.id">
                    <a href="javascript:;" class="nav-link d-flex justify-content-between w-100" @click="handlerBtnTopupDetail(item)">
                      <el-tag size="mini" :type="item.tag_type">{{ item.topup_amount_str }}</el-tag>
                      <small>{{ item.topup_date }}</small>
                    </a>
                  </li>
                </ul>
                <div align="center">
                  <el-button @click="$router.push({ name: 'Topup Histories', params: { workspace_id: activeWorkspace._id }})" type="text" size="small">Show all histories</el-button>
                </div>
              </template>
              <div v-else>
                <el-empty :image="lostSign" description="No topup histories."></el-empty>
              </div>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown nav-profile">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="profileDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            <img :src="userAvatar" alt="profile">
          </a>
          <el-badge
            v-if="isAgent"
            class="nav-link dropdown-toggle"
            id="profileDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            is-dot :type="agent_availibility_color"></el-badge>
          <div class="dropdown-menu" aria-labelledby="profileDropdown">
            <div class="dropdown-header d-flex flex-column align-items-center">
              <div class="figure mb-3">
                <img :src="userAvatar" alt="">
              </div>
              <div class="info text-center">
                <p class="name font-weight-bold mb-0">{{ userProfile.name }}</p>
                <p class="text-muted mb-3">{{ userProfile.email }}</p>
              </div>
            </div>
            <div class="dropdown-body">
              <ul class="profile-nav p-0 pt-3">
                <!-- <li class="nav-item">
                  <a href="pages/general/profile.html" class="nav-link">
                    <FeatherIcon type="user" />
                    <span>Profile</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a href="javascript:;" class="nav-link">
                    <FeatherIcon type="edit" />
                    <span>Edit Profile</span>
                  </a>
                </li> -->
                <li class="nav-item" v-if="isAgent">
                  <a href="javascript:;" class="nav-link" @click="handlerBtnSetAvailibility">
                    <FeatherIcon type="power" />
                    Set Availibility
                  </a>
                </li>
                <li class="nav-item">
                  <a href="javascript:;" class="nav-link" @click="showModal = true;">
                    <FeatherIcon type="lock" />
                    <span>Change password</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a href="javascript:;" class="nav-link" @click="doShowModalCompanyProfile">
                    <font-awesome :icon="['far', 'building']"/>
                    <span>Company Profile</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a href="javascript:;" class="nav-link" @click="doShowModalAccountProfile">
                    <FeatherIcon type="users" />
                    <span>Account Profile</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a href="javascript:;" class="nav-link" @click.prevent="doLogout()">
                    <FeatherIcon type="log-out" />
                    <span>Log Out</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- v-if="routeMeta.layout !== 'topOnly' && this.call_session?.session" -->
    <preview-minimize-call
      :show="showModalMinimizeCall"
      :call_session="call_session"
      :phone_call="phone_call"
      :call="call"
      @onHide="showModalMinimizeCall = false"
      @handleMicPhoneCall="handleMicPhoneCall"
    />
    <preview-maximize-call
      :show="showModalMaximizeCall"
      :call_session="call_session"
      :phone_call="phone_call"
      :call="call"
      @onHide="showModalMaximizeCall = false"
    />
    <audio class="hidden" id="audio" ref="audio" autoplay controls></audio>
    <!-- <preview-incoming-call
      :show="showModalIncomingCall"
      :call_session="call_session"
      @onHide="showModalIncomingCall = false"
    /> -->
    <b-modal v-model="showModal" title="Change Password" hide-footer>
      <b-form @submit.prevent="changePassword" @reset="closeModal">
        <b-form-group id="ig-oldpassword" label="Old password:" label-for="i-passwordl">
          <el-input show-password id="i-oldpassword" type="password" placeholder="Input your old password" required v-model="v$.form.old_password.$model" />
          <b-form-invalid-feedback :state="!v$.form.old_password.$invalid" v-if="v$.form.old_password.$errors[0]">
            {{ v$.form.old_password.$errors[0].$message }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="ig-newpassword" label="New password:" label-for="i-passwordl">
          <el-input show-password id="i-newpassword" type="password" placeholder="Input your new password" required v-model="v$.form.new_password.$model" />
          <b-form-invalid-feedback :state="!v$.form.new_password.$invalid" v-if="v$.form.new_password.$errors[0]">
            {{ v$.form.new_password.$errors[0].$message }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="ig-confirmpassword" label="Re-type new password:" label-for="i-passwordl">
          <el-input show-password id="i-confirmpassword" type="password" placeholder="Re-type your new password" required v-model="v$.form.confirm_password.$model" />
          <b-form-invalid-feedback :state="!v$.form.confirm_password.$invalid" v-if="v$.form.confirm_password.$errors[0]">
            {{ v$.form.confirm_password.$errors[0].$message }}
          </b-form-invalid-feedback>
        </b-form-group>

        <div class="d-flex flex-row-reverse">
          <el-button native-type="submit" :loading="loading.change_password" size="small" class="ml-2 btn-primary font-weight-bold">{{ $t('general.submit') }}</el-button>
          <el-button native-type="reset" size="small" class="btn-secondary font-weight-bold" >{{ $t('general.cancel') }}</el-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal v-model="showModalCompanyProfile" title="Company Profile" hide-footer>
      <b-form @submit.prevent="updateCompanyProfile" @reset="closeModalCompanyProfile">
        <b-form-group label="Company Name">
          <b-form-input
            v-model="v$.company_form.name.$model"
            placeholder="Your company name">
          </b-form-input>
          <b-form-invalid-feedback :state="!v$.company_form.name.$invalid" v-if="v$.company_form.name.$errors[0]">
            {{ $t('validation.' + v$.company_form.name.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Company Brand">
          <b-form-input
            v-model="v$.company_form.brand_name.$model"
            placeholder="Your company brand">
          </b-form-input>
          <b-form-invalid-feedback :state="!v$.company_form.brand_name.$invalid" v-if="v$.company_form.brand_name.$errors[0]">
            {{ $t('validation.' + v$.company_form.brand_name.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Company Address">
          <b-form-textarea
            v-model="v$.company_form.address.$model"
            rows="3"
            placeholder="Your company address">
          </b-form-textarea>
          <b-form-invalid-feedback :state="!v$.company_form.address.$invalid" v-if="v$.company_form.address.$errors[0]">
            {{ $t('validation.' + v$.company_form.address.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Business Entities">
          <el-select class="w-100" v-model="v$.company_form.business_entity_id.$model" placeholder="Select your business entities">
            <el-option v-for="item in business_entities" :key="item.id" :label="item.name" :value="item.id"/>
          </el-select>
          <b-form-invalid-feedback :state="!v$.company_form.business_entity_id.$invalid" v-if="v$.company_form.business_entity_id.$errors[0]">
            {{ $t('validation.' + v$.company_form.business_entity_id.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Business Type">
          <el-select filterable class="w-100" v-model="v$.company_form.business_type_id.$model" placeholder="Select your business type">
            <el-option v-for="item in business_types" :key="item.id" :label="item.name" :value="item.id"/>
          </el-select>
          <b-form-invalid-feedback :state="!v$.company_form.business_type_id.$invalid" v-if="v$.company_form.business_type_id.$errors[0]">
            {{ $t('validation.' + v$.company_form.business_type_id.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Company Logo">
          <img
            :src="this.companyProfile.logo_src ? this.companyProfile.logo_src : 'https://static.vecteezy.com/system/resources/previews/005/337/799/original/icon-image-not-found-free-vector.jpg'"
            style="object-fit: contain; width: 30%; height: 25%;" />

          <b-form-file
            accept="image/*"
            placeholder="Choose a file or drop it here."
            drop-placeholder="Drop file here."
            v-model="company_form.file"
          ></b-form-file>
          <el-progress v-if="loading.upload_file" :percentage="upload_progress" :status="upload_status"></el-progress>
        </b-form-group>
        <div class="d-flex flex-row-reverse">
          <el-button native-type="submit" :loading="loading.update_compro" size="small" class="ml-2 btn-primary font-weight-bold">{{ $t('general.submit') }}</el-button>
          <el-button native-type="reset" size="small" class="btn-secondary font-weight-bold" >{{ $t('general.cancel') }}</el-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal v-model="showModalAccountProfile" title="Account Profile" hide-footer>
      <b-form @submit.prevent="updateAccountProfile" @reset="closeModalAccountProfile">
        <b-form-group label="Name">
          <b-form-input
            v-model="v$.account_form.name.$model"
            placeholder="Enter your name">
          </b-form-input>
          <b-form-invalid-feedback :state="!v$.account_form.name.$invalid" v-if="v$.account_form.name.$errors[0]">
            {{ $t('validation.' + v$.account_form.name.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Email">
          <b-form-input
            v-model="v$.account_form.email.$model"
            placeholder="Enter your email">
          </b-form-input>
          <b-form-invalid-feedback :state="!v$.account_form.email.$invalid" v-if="v$.account_form.email.$errors[0]">
            {{ $t('validation.' + v$.account_form.email.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Phone Number">
          <b-form-input
            v-model="v$.account_form.phone_number.$model"
            placeholder="Enter your phone number">
          </b-form-input>
          <b-form-invalid-feedback :state="!v$.account_form.phone_number.$invalid" v-if="v$.account_form.phone_number.$errors[0]">
            {{ $t('validation.' + v$.account_form.phone_number.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Password">
          <b-form-input
            placeholder="Enter your password"
            autocomplete="one-time-code"
            type="password"
            v-model="account_form.password" />
          <b-form-invalid-feedback :state="!v$.account_form.password.$invalid" v-if="v$.account_form.password.$errors[0]">
            {{ $t('validation.' + v$.account_form.password.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Photo Profile">
          <img
            :src="this.accountProfile.profile_src ? this.accountProfile.profile_src : 'https://static.vecteezy.com/system/resources/previews/005/337/799/original/icon-image-not-found-free-vector.jpg'"
            style="object-fit: contain; width: 30%; height: 25%;" />

          <b-form-file
            accept="image/*"
            placeholder="Choose a file or drop it here."
            drop-placeholder="Drop file here."
            v-model="account_form.file"
          ></b-form-file>
          <el-progress v-if="loading.upload_file" :percentage="upload_progress" :status="upload_status"></el-progress>
        </b-form-group>
        <div class="d-flex flex-row-reverse">
          <el-button native-type="submit" :loading="loading.update_accpro" size="small" class="ml-2 btn-primary font-weight-bold">{{ $t('general.submit') }}</el-button>
          <el-button native-type="reset" size="small" class="btn-secondary font-weight-bold" >{{ $t('general.cancel') }}</el-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal size="lg" v-model="showModalTopup" title="Balance Topup" hide-footer @hide="onHideTopup">
      <el-steps :active="current_step" finish-status="success" simple="">
        <el-step title="Fill Data" icon="el-icon-warning-outline"></el-step>
        <el-step title="Confirmation" icon="el-icon-s-claim"></el-step>
        <el-step title="Payment Detail" icon="el-icon-postcard"></el-step>
      </el-steps>
      <template v-if="current_step == 0">
        <b-form>
          <b-form-group label="Topup Amount">
            <el-radio-group @change="handlerChangeAmountBtn" v-model="form1.topup_amount" class="w-100">
              <el-radio-button v-for="(item, index) in amount_opt" :key="index" :value="item.value" :label="item.text"></el-radio-button>
            </el-radio-group>
            <el-input placeholder="Please input topup amount" v-model="form1.amount" class="mt-2">
              <template slot="prepend">Rp</template>
            </el-input>
            <b-form-invalid-feedback :state="!v$.form1.amount.$invalid" v-if="v$.form1.amount.$error">
            {{ v$.form1.amount.$errors[0].$message }}
          </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Payment Method">
            <el-select v-model="form1.payment_method_id" placeholder="Please choose payment method" class="w-100">
              <el-option v-for="item in sof" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
            <b-form-invalid-feedback :state="!v$.form1.payment_method_id.$invalid" v-if="v$.form1.payment_method_id.$error">
            {{ $t('validation.' + v$.form1.payment_method_id.$errors[0].$validator) }}</b-form-invalid-feedback>
          </b-form-group>
          <div class="d-flex flex-row float-right mt-2">
          <el-button @click="handlerBtnNextTopup" class="mr-2 ml-2" size="small" type="primary">Next</el-button>
          <el-button @click="showModalTopup = false" size="small" class="mr-2">Cancel</el-button>
        </div>
        </b-form>
      </template>
      <template v-if="current_step == 1">
        <el-descriptions class="mt-2" title="Topup Information" :column="1" border>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-money"></i>
              Topup Amount
            </template>
            {{ confirmation_detail.topup_amount }}
          </el-descriptions-item>
          <el-descriptions-item v-if="confirmation_detail.ppn_amount">
            <template slot="label">
              <i class="el-icon-tickets"></i>
              VAT 11%
            </template>
            {{ confirmation_detail.ppn_amount }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-bank-card"></i>
              Payment Method
            </template>
            {{ confirmation_detail.payment_method }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <font-awesome icon="user"/>
              Admin Fee
            </template>
            {{ confirmation_detail.admin_fee }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <font-awesome icon="money-bill-wave"/>
              Total Payment
            </template>
            {{ confirmation_detail.total_transfer }}
          </el-descriptions-item>
        </el-descriptions>
        <div class="d-flex flex-row float-left mt-2">
          <el-button @click="current_step -= 1" type="info" size="small" class="mr-2">Back</el-button>
        </div>
        <div class="d-flex flex-row float-right mt-2">
          <el-button @click="handlerBtnNextTopup" class="mr-2 ml-2" size="small" type="primary">Next</el-button>
          <el-button @click="showModalTopup = false" size="small" class="mr-2">Cancel</el-button>
        </div>
      </template>
      <template v-if="current_step == 2">
        <p class="mt-2 mb-2">Please transfer to this following detail. You should pay before <strong class="text-red">{{ resp.expired_at_str }}</strong></p>
        <el-descriptions class="margin-top" title="" :column="1" border>
          <el-descriptions-item>
            <template slot="label">
              <!-- <font-awesome icon="money-bill-wave"/> -->
              <i class="el-icon-document"></i>
              Invoice Number
            </template>
            {{ resp.invoice_number }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-money"></i>
              Topup Amount
            </template>
            {{ confirmation_detail.topup_amount }}
          </el-descriptions-item>
          <el-descriptions-item v-if="confirmation_detail.ppn_amount">
            <template slot="label">
              <i class="el-icon-tickets"></i>
              VAT 11%
            </template>
            {{ confirmation_detail.ppn_amount }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <font-awesome icon="user"/>
              Admin Fee
            </template>
            {{ confirmation_detail.admin_fee }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <font-awesome icon="money-bill-wave"/>
              Total Payment
            </template>
            {{ confirmation_detail.total_transfer }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-bank-card"></i>
              Payment Method
            </template>
            {{ confirmation_detail.payment_method }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <font-awesome icon="user"/>
              Virtual Account Number
            </template>
            {{ resp.virtual_account_number }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <font-awesome icon="calendar"/>
              Expired Date
            </template>
            {{ resp.expired_at_str }}
          </el-descriptions-item>
        </el-descriptions>
        <div class="mt-3 mb-3"><strong>How to Topup</strong></div>
        <el-collapse v-model="active_collapse_howtopay" accordion>
          <el-collapse-item v-for="(item, index) in resp.how_to_pay" :key="index" :title="item.channel" :name="index">
            <ol>
              <li v-for="(step, i) in item.step" :key="i">{{ step }}</li>
            </ol>
          </el-collapse-item>
        </el-collapse>
        <div class="d-flex flex-row float-right mt-2">
          <el-button v-if="!is_waiting_payment" @click="handlerBtnNextTopup" class="mr-2 ml-2" size="small" type="primary">Done</el-button>
          <el-button v-else @click="handlerBtnCancelRequest" class="mr-2 ml-2" size="small" type="primary">Cancel Request</el-button>
          <el-button @click="showModalTopup = false" size="small" class="mr-2">Close</el-button>
        </div>
      </template>
    </b-modal>
    <b-modal size="lg" v-model="showModalTopupDetail" title="Topup Request" hide-footer @hide="onHideTopup">
      <el-descriptions class="margin-top" title="" :column="1" border>
          <el-descriptions-item>
            <template slot="label">
              <!-- <font-awesome icon="money-bill-wave"/> -->
              <i class="el-icon-document"></i>
              Invoice Number
            </template>
            {{ topup_history_detail.invoice_number }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-money"></i>
              Topup Amount
            </template>
            {{ topup_history_detail.topup_amount_str }}
          </el-descriptions-item>
          <el-descriptions-item v-if="topup_history_detail.ppn_amount">
            <template slot="label">
              <i class="el-icon-tickets"></i>
              VAT 11%
            </template>
            {{ topup_history_detail.ppn_amount_str }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <font-awesome icon="user"/>
              Admin Fee
            </template>
            {{ topup_history_detail.admin_fee_str }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <font-awesome icon="money-bill-wave"/>
              Total Payment
            </template>
            {{ topup_history_detail.total_transaction_str }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-bank-card"></i>
              Payment Method
            </template>
            {{ topup_history_detail.payment_method_str }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <font-awesome icon="barcode"/>
              Virtual Account Number
            </template>
            {{ topup_history_detail.virtual_account_number }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <font-awesome icon="money-check"/>
              Payment Status
            </template>
            <el-tag size="small" :type="topup_history_detail.tag_type">{{ topup_history_detail.status_str }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item v-if="topup_history_detail.creator">
            <template slot="label">
              <!-- <font-awesome icon="money-check"/> -->
              <i class="el-icon-s-custom"></i>
              Request By
            </template>
            {{ topup_history_detail.creator.name }}
          </el-descriptions-item>
          <el-descriptions-item v-if="topup_history_detail.status !== 'paid'">
            <template slot="label">
              <font-awesome icon="calendar"/>
              Expired Date
            </template>
            {{ topup_history_detail.expired_at_str }}
          </el-descriptions-item>
          <el-descriptions-item v-if="topup_history_detail.status === 'paid'">
            <template slot="label">
              <font-awesome icon="calendar"/>
              Paid Date
            </template>
            {{ topup_history_detail.paid_at_str }}
          </el-descriptions-item>
      </el-descriptions>
      <div class="flex justify-between-content mt-2">
        <a :href="topup_history_detail.topup_receipt_url" class="text-normal" target="_blank">
          <font-awesome icon="file-pdf"/>&nbsp;Download Invoice
        </a>
        <el-button @click="showModalTopupDetail = false" size="small" class="float-right">Close</el-button>
      </div>
    </b-modal>
  </nav>
</template>

<script>
import lostSign from '@/assets/images/undraw/lost-sign.svg';
import moment from 'moment';
// import JsSIP from 'jssip';
import { clone, isEmpty } from 'lodash';
import useVuelidate from '@vuelidate/core';
import {
  required, numeric, minValue, sameAs, helpers, minLength, email, maxLength,
} from '@vuelidate/validators';
import config from '@/config';
import openSocket from 'socket.io-client';

import avatar from '../../library/avatar';
import authApi from '../../api/auth';
import profileApi from '../../api/profile';
import workspaceApi from '../../api/workspaces';
import conversationsAPI from '../../api/conversations';
import popupErrorMessages from '../../library/popup-error-messages';
import topupApi from '../../api/topup';
import agents from '../../api/agents';
// import store from '../../store';
import passwordComplexity from '../../library/password-complexity';
import PreviewMinimizeCall from './conversations/PreviewMinimizeCall.vue';
// import PreviewIncomingCall from './conversations/PreviewIncomingCall.vue';
import PreviewMaximizeCall from './conversations/PreviewMaximizeCall.vue';
import RingtoneSound from '../../assets/sound/Ringtone_1.mp3';
import CallWaitingSound from '../../assets/sound/CallWaiting_1.mp3';
// import PreviewPhoneCall from './conversations/PreviewPhoneCall.vue';
import NotifIncomingCall from '../../assets/sound/notif-call-waiting.mp3';

// const connect_sip = new JsSIP.WebSocketInterface('wss://process.env.VUE_APP_SIP_DOMAIN:8089/ws');
// const configuration = {
//   sockets: [connect_sip],
//   uri: `sip:${store.state.auth.extensions}@process.env.VUE_APP_SIP_DOMAIN`,
//   password: 'WAPPIN2024',
//   display_name: store.state.auth.userProfile.name,
// };
// const socket_sip = new JsSIP.UA(configuration);
const ui_call_socket = {
  button_call_color: 'text-secondary',
  button_call_active: false,
  contact_call_color: 'outline-secondary',
};
export { ui_call_socket };

export default {
  name: 'Navbar',
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    PreviewMaximizeCall,
    PreviewMinimizeCall,
    // PreviewIncomingCall,
  },
  metaInfo() {
    return {
      titleTemplate: (s) => {
        this.title = s;
        return (s ? `${s} | Wappin` : 'Wappin');
      },
    };
  },
  data() {
    return {
      ui_call_socket,
      call_details: {},
      call_session: {},
      call: {
        duration: 0,
        startTime: '',
        talktime: 0,
        startCall: '',
      },
      phone_call: {
        status: 'Trying...',
        mic: 'mdi mdi-microphone mdi-24px',
        volume: 'mdi mdi-volume-low mdi-24px',
        name: '',
        phone_number: '',
        conversation_id: '',
      },
      audio_ringtone: new Audio(RingtoneSound),
      audio_ringing: new Audio(CallWaitingSound),
      offerOptions: {
        offerToReceiveAudio: 1,
        offerToReceiveVideo: 0,
      },
      showModalIncomingCall: false,
      showModalMinimizeCall: false,
      showModalMaximizeCall: false,
      isPhoneMuted: false,
      lostSign,
      title: '',
      showModal: false,
      showModalCompanyProfile: false,
      form: {
        old_password: '',
        new_password: '',
        confirm_password: '',
      },
      showModalAccountProfile: false,
      upload_status: '',
      upload_progress: '',
      loading: {
        change_password: false,
        update_compro: false,
        update_accpro: false,
        balance_histories: false,
        upload_file: false,
        phone_call: false,
      },
      company_form: {
        name: '',
        brand_name: '',
        address: '',
        business_type_id: '',
        business_entity_id: '',
      },
      account_form: {
        name: '',
        email: '',
        phone_number: '',
        password: '',
        file: [],
      },
      active_collapse_howtopay: '',
      business_entities: [],
      business_types: [],
      companyProfile: {},
      accountProfile: {},
      loader: null,
      loaderStack: 0,
      showModalTopup: false,
      showModalTopupDetail: false,
      current_step: 0,
      form1: {
        topup_amount: '',
        amount: null,
        payment_method_id: null,
      },
      confirmation_detail: {},
      amount_opt: [
        {
          value: 50000,
          text: 'Rp 50.000',
        },
        {
          value: 100000,
          text: 'Rp 100.000',
        },
        {
          value: 200000,
          text: 'Rp 200.000',
        },
        {
          value: 500000,
          text: 'Rp 500.000',
        },
      ],
      sof: [],
      resp: {
        how_to_pay: [],
      },
      topup_histories_list: [],
      is_waiting_payment: false,
      topup_history_detail: {},
      calculate_topup_result: {},
      agent_availibility_color: 'danger',
      is_agent_availibility: false,
      call_message_data: {
        msg_id: '',
      },
      pc: null,
      localIceCandidates: new Set(),
      localStream: null,
      remoteStream: new MediaStream(),
      sdp_offer: null,
      socket: null,
      call_direction: '',
      conversation: null,
      // for record local
      mediaRecorder: null,
      audioChunks: [],
      current_call_id: null,
      soundIncomingCall: new Audio(NotifIncomingCall),
      successPingCall: [],
    };
  },
  validations() {
    return {
      company_form: {
        name: { required },
        brand_name: { required },
        address: { required },
        business_type_id: { required },
        business_entity_id: { required },
      },
      account_form: {
        name: {
          required,
        },
        email: {
          required,
          email,
        },
        phone_number: {
          required,
          numeric,
          minLength: minLength(9),
          maxLength: maxLength(15),
        },
        password: {
          minLength: minLength(8),
        },
      },
      form1: {
        amount: {
          required,
          numeric,
          minValue: minValue(10000),
        },
        payment_method_id: { required },
      },
      form: {
        old_password: {
          required,
        },
        new_password: {
          required,
          minLength: minLength(8),
          complexity: helpers.withMessage(() => 'Password should have at least 1 uppercase, 1 lowercase, 1 number and 1 special character', passwordComplexity),
        },
        confirm_password: {
          required,
          sameAs: sameAs(this.form.new_password, 'new password'),
          minLength: minLength(8),
          complexity: helpers.withMessage(() => 'Password should have at least 1 uppercase, 1 lowercase, 1 number and 1 special character', passwordComplexity),
        },
      },
    };
  },
  mounted() {
    this.checkWorkspaceBalance();
    this.agent_availibility_color = this.userProfile.agents[0]?.is_available ? 'success' : 'danger';
    this.checkAvailibility();
    // socket sip for call
    if (this.SIPsocket) {
      if (this.activeWorkspace._id) {
        this.SIPsocket.start();
      }
      this.SIPsocket.on('connected', () => {
        this.check_socket_sip('1');
        console.log('connect socket sip navbar');
      });
      this.SIPsocket.on('disconnected', () => {
        this.check_socket_sip('0');
        console.log('disconnected socket sip navbar');
      });
      this.SIPsocket.on('newRTCSession', async (e) => {
        console.log('new rtc session created - incoming or outgoing call di navbar');
        console.log(e.session);
        this.call.startCall = moment.utc();
        this.call_session = e;

        if (e.originator === 'local') {
          console.log(' outgoing session');
          const selected_contact = this.call_session.session.data.conversation.contacts.find((v) => v.channel === 'whatsapp');
          this.phone_call.name = selected_contact.name;
          this.phone_call.phone_number = selected_contact.phone_number;
          this.phone_call.status = 'Calling...';
          this.phone_call.conversation_id = this.call_session.session.data.conversation.id;
          const form_data = {
            call_id: this.call_session.session.id,
            phone_number: this.phone_call.phone_number,
            workspace_id: this.activeWorkspace._id,
            conversation_id: this.phone_call.conversation_id,
          };
          this.createOutgoingCall(form_data);
          const pc = e.session.connection;
          console.log('pc nih');
          console.log(pc);
          const remoteStream = new MediaStream();
          pc.getReceivers().forEach((receiver) => {
            if (receiver.track) {
              remoteStream.addTrack(receiver.track);
            }
          });
          console.log('remoteStream nih');
          console.log(remoteStream);
          // const audioContext = new AudioContext();
          // const remoteAudio = audioContext.createMediaStreamSource(remoteStream);
          // remoteAudio.connect(audioContext.destination);
        } else {
          console.log(' incoming session');
          this.getIncomingCall();
        }
        // const devices = await navigator.mediaDevices.enumerateDevices();
        // const audioDevice = devices.find((device) => device.kind === 'audiooutput');
        // const audio = new Audio();
        // await audio.setSinkId(audioDevice.deviceId);
        // console.log(`Audio is being output on ${audio.sinkId}`);
        // this.call_session.session.connection.addEventListener('addstream', (event) => {
        //   console.log('event stream');
        //   console.log(event.stream);
        //   // const audio_calling = new Audio(event.stream);
        //   // audio_calling.play();
        //   const audioContext = new AudioContext();
        //   const audioSourceNode = audioContext.createMediaStreamSource(event.stream);
        //   audioSourceNode.connect(audioContext.destination);
        // });
        // console.log('this.call_session.session', this.call_session.session);
        // this.call_session.session.on('peerconnection', (data) => {
        //   console.log('peer connection', data);
        //   const pc = e.peerconnection;
        //   const signaling = e.signalingChannel;
        //   if (pc.connectionState === 'connected') {
        //     console.log('Peers connected!');
        //   }
        //   pc.onicecandidate = ({ candidate }) => signaling.send({ candidate });
        //   // Let the "negotiationneeded" event trigger offer generation.
        //   pc.onnegotiationneeded = async () => {
        //     try {
        //       await pc.setLocalDescription(await pc.createOffer());
        //       // Send the offer to the other peer.
        //       signaling.send({ desc: pc.localDescription });
        //     } catch (err) {
        //       console.error(err);
        //     }
        //   };
        //   pc.ontrack = (event) => {
        //     const audioContext = new AudioContext();
        //     const audioSourceNode = audioContext.createMediaStreamSource(event.streams[0]);
        //     audioSourceNode.connect(audioContext.destination);
        //   };
        // });
        this.call_session.session.on('progress', (b) => {
          console.log('rtc - Progress');
          console.log(b);
          if (this.call_session.originator === 'remote') {
            this.phone_call.status = 'Incoming Call...';
          } else {
            if (b.response.status_code === 183) {
              this.audio_ringing.loop = true;
              this.audio_ringing.play();
            }
            this.phone_call.status = 'Ringing...';
          }
        });
        this.call_session.session.on('accepted', (as) => {
          console.log('rtc - Accepted');
          console.log(as);
          const pc = this.call_session.session.connection;
          console.log('pc nih');
          console.log(pc.remoteDescription);
          const remoteStream = new MediaStream();
          pc.getReceivers().forEach((receiver) => {
            if (receiver.track) {
              remoteStream.addTrack(receiver.track);
            }
          });
          // const audio_output = navigator.mediaDevices.enumerateDevices((media) => media.kind === 'audiooutput' && media.deviceId === 'default');
          // console.log(audio_output);
          const audioContext = new AudioContext();
          const remoteAudio = audioContext.createMediaStreamSource(remoteStream);
          remoteAudio.connect(audioContext.destination);
          // remoteAudio.play();
          remoteAudio.onloadedmetadata = ((e_remote) => {
            console.log('e remote');
            console.log(e_remote);
            if (typeof remoteAudio.sinkId !== 'undefined') {
              // await audio.setSinkId(audioDevice.deviceId);
              remoteAudio.setSinkId('default').then(() => {
                console.log('sinkId applied: ', 'default');
              }).catch((err) => {
                console.warn('Error using setSinkId: ', err);
              });
            }
            remoteAudio.play();
          });
          console.log('remoteStream nih');
          console.log(remoteStream);
          console.log(this.call_session.session);
          this.phone_call.status = 'Connecting...';
          if (this.call_session.originator === 'remote') {
            this.audio_ringtone.pause();
          } else {
            this.call.startTime = moment.utc();
            this.audio_ringing.pause();
          }
        });
        this.call_session.session.on('confirmed', (b) => {
          console.log('rtc - Confirmed');
          console.log(this.call_session.session);
          console.log(b);
          this.phone_call.status = 'You are on a call with a customer';
          if (this.call_session.originator === 'local') {
            this.call.talktime = setInterval(() => {
              if (this.call.startTime) {
                const now = moment.utc();
                const duration = moment.duration(now.diff(this.call.startTime, 'second'));
                if (duration > 3600) {
                  this.call.duration = moment.utc(duration * 1000).format('HH:mm:ss');
                } else {
                  this.call.duration = moment.utc(duration * 1000).format('mm:ss');
                }
                if ((duration % 60) === 0) {
                  console.log('total duration', (duration / 60));
                  // todo - charge per minute
                }
              }
            }, 1000);
          }
        });
        this.call_session.session.on('ended', (b) => {
          console.log('rtc - Ended');
          console.log(b);
          b.status = 'ended';
          this.endCall(b);
          this.handleEndPhoneCall();
        });
        this.call_session.session.on('failed', (b) => {
          console.log('rtc - Failed');
          console.log(b);
          b.status = 'failed';
          if (this.call_session.originator === 'remote') {
            this.audio_ringtone.pause();
          } else if (this.call_session.originator === 'local') {
            this.audio_ringing.pause();
          }
          this.endCall(b);
          this.handleEndPhoneCall();
        });
      });
    }
    console.log('Navbar mounted');
    if (this.userProfile.agents[0].assigned_call_feature && this.isEnableCallFeature) {
      this.socket = openSocket(config.api.chatUrl, {
        transports: ['websocket'],
        upgrade: false,
        reconnection: true,
        reconnectionAttempts: 1000,
        reconnectionDelay: 1000,
      });
      this.$nextTick(() => {
        console.log('Connecting tick..');
        this.socket.connect();
      });
      this.socket.on('disconnect', () => {
        console.log('Disconnected, reconnecting...');
        this.socket.connect();
      });
      this.socket.on('connect', () => {
        console.log('connected to socket navbar');
        if (this.activeWorkspace._id) {
          this.socket.emit('ws-conversation', `conversation:${this.activeWorkspace._id}:call`);
          if (this.$store.state.auth.userProfile.agents[0]) {
            const agent_id = this.$store.state.auth.userProfile.agents[0].id;
            this.socket.emit('conversation', agent_id);
          }
        }
        this.rtcSetupShared();
      });
      this.socket.on('listen_call', async (data) => {
        const {
          message, session, call_action, action_by,
        } = data;
        console.log('--> incoming call', call_action);
        if (call_action === 'terminate') {
          if (message.msg_id !== this.current_call_id && this.call.duration) {
            return;
          }
          if (this.showModalMinimizeCall) {
            await this.handleEndPhoneCall();
          }
          return;
        }
        if (call_action === 'ringing') {
          return;
        }
        if (call_action === 'accepted') {
          if (message.msg_id !== this.current_call_id) {
            return;
          }
          this.call = {
            duration: 1,
            startCall: '',
          };
          this.call.startTime = moment.utc();
          this.call.talktime = setInterval(() => {
            if (this.call.startTime) {
              const now = moment.utc();
              const duration = moment.duration(now.diff(this.call.startTime, 'second'));
              if (duration > 3600) {
                this.call.duration = moment.utc(duration * 1000).format('HH:mm:ss');
              } else {
                this.call.duration = moment.utc(duration * 1000).format('mm:ss');
              }
              if ((duration % 60) === 0) {
                const total_duration = duration / 60;
                console.log('total duration', total_duration);
                this.socket.emit('call-per-minute', { call_id: this.call_message_data.msg_id, total_duration });
                conversationsAPI.pingCall({
                  call_id: this.call_message_data.msg_id,
                  total_duration,
                }).then((responsePing) => {
                  if (!responsePing.error) {
                    this.successPingCall.push(total_duration);
                  }
                });
              }
            }
          }, 1000);
          return;
        }
        if (call_action === 'rejected') {
          // await this.handleEndPhoneCall();
          return;
        }
        if (['accept', 'reject'].includes(call_action)) { // handle if agent accept or reject call
          if (message.msg_id !== this.current_call_id && this.call.duration) {
            return;
          }
          if (call_action === 'reject') {
            if (action_by === this.userProfile.id) {
              this.showModalMinimizeCall = false;
            }
          }
          if (call_action === 'accept') {
            if (message && message.msg_id !== this.current_call_id) {
              this.call_message_data = {};
              this.handleEndPhoneCall();
            }
          }
          return;
        }
        if (this.call.duration) { // receive socket but user still on another call, ignore
          return;
        }
        this.call_message_data = message;
        this.call_direction = session.sdp_type === 'answer' ? 'outgoing' : 'incoming';
        if (this.call_direction === 'incoming') {
          this.soundIncomingCall.loop = true;
          this.soundIncomingCall.play();
        }
        this.phone_call = {
          name: this.call_direction === 'outgoing' ? this.conversation.selected_contact.name : (message.contact.display_name || message.contact.name),
          phone_number: this.call_direction === 'outgoing' ? this.conversation.selected_contact.phone_number : message.contact.phone_number,
          status: session.sdp_type === 'answer' ? 'Ringing' : 'Incoming call...',
          originator: this.call_direction === 'outgoing' ? 'outgoing' : 'remote',
          mic: 'mdi mdi-microphone mdi-24px',
          volume: 'mdi mdi-volume-low mdi-24px',
        };
        this.call = {
          duration: 0,
          startTime: '',
          talktime: 0,
          startCall: '',
        };
        this.call_session = {
          originator: this.call_direction === 'outgoing' ? 'outgoing' : 'remote',
        };
        session.type = session.sdp_type;
        this.sdp_offer = session;
        this.showModalMinimizeCall = true;
        this.socket.emit('ws-conversation', `conversation:${this.activeWorkspace._id}:call:${this.call_message_data.msg_id}`);
        if (session.sdp_type === 'answer') {
          this.handleAnswerIncomingCall();
        }
      });
    }
  },
  computed: {
    userProfile() {
      return this.$store.state.auth.userProfile || {};
    },
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace || {};
    },
    userAvatar() {
      return avatar(this.userProfile.name, this.userProfile.email);
    },
    routeMeta() {
      return this.$route.meta;
    },
    workspaceBalance() {
      return this.$store.state.workspace.workspaceBalance?.toLocaleString('id-ID') || 0;
    },
    workspaceFreeQuota() {
      return this.$store.state.workspace.workspaceFreeQuota;
    },
    isAgent() {
      return !isEmpty(this.userProfile.agents[0]);
    },
    isEnableCallFeature() {
      return this.activeWorkspace && this.activeWorkspace.products ? this.activeWorkspace.products[0].pricing.whatsapp.enable_call_feature : false;
    },
  },
  methods: {
    doConnectSocket() {
      if (this.socket) this.socket.connect();
    },
    check_socket_sip(connect) {
      if (connect === '1') {
        this.ui_call_socket.button_call_color = 'text-black';
        this.ui_call_socket.button_call_active = true;
        this.ui_call_socket.contact_call_color = 'outline-primary';
      } else {
        this.ui_call_socket.button_call_color = 'text-secondary';
        this.ui_call_socket.button_call_active = false;
        this.ui_call_socket.contact_call_color = 'outline-black';
      }
    },
    async createSdpOffer(cb) {
      if (!this.pc) {
        await this.rtcSetupShared();
      }
      if (this.sdp_offer && this.sdp_offer.sdp) {
        this.$message({
          message: 'Failed. You are still on another call.',
          type: 'error',
        });
        return;
      }
      const offerOptions = {
        offerToReceiveAudio: 1,
        offerToReceiveVideo: 0,
        voiceActivityDetection: false,
      };
      await this.setupAudio();
      this.pc.createOffer(offerOptions).then((offer) => {
        console.log('Got local Offer:');
        this.pc.setLocalDescription(offer);
        cb(null, offer);
      }, (error) => console.log(`Failed to create session description: ${error.toString()}`));
    },
    async waitCallbackSdp(call_id, conversation) {
      this.socket.emit('ws-conversation', `conversation:${this.activeWorkspace._id}:call:${call_id}`);
      this.conversation = conversation;
      this.showModalMinimizeCall = true;
      this.phone_call = {
        name: this.conversation.selected_contact.name,
        phone_number: this.conversation.selected_contact.phone_number,
        status: 'Connecting...',
        originator: 'outgoing',
        mic: 'mdi mdi-microphone mdi-24px',
        volume: 'mdi mdi-volume-low mdi-24px',
      };
      this.call = {
        duration: 0,
        startTime: '',
        talktime: 0,
        startCall: '',
      };
      this.call_session = {
        originator: 'outgoing',
      };
    },
    async createOutgoingCall(form_data) {
      await conversationsAPI.outgoingConvoCall(form_data)
        .then(async (response) => {
          await popupErrorMessages(response);
          this.showModalMaximizeCall = true;
          console.log(response);
        }).catch((err) => {
          console.log(err);
          this.handleEndPhoneCall();
        });
    },
    async getIncomingCall() {
      this.phone_call.status = 'Incoming Call';
      this.phone_call.phone_number = `62${this.call_session.session.remote_identity.uri.user}`;
      if (this.call_session.session.remote_identity.uri.user.startsWith('0')) {
        this.phone_call.phone_number = `62${this.call_session.session.remote_identity.uri.user.substring(1)}`;
      }
      if (this.call_session.session.remote_identity.uri.user.startsWith('+62')) {
        this.phone_call.phone_number = `62${this.call_session.session.remote_identity.uri.user.substring(3)}`;
      }
      const form_data = {
        call_id: this.call_session.session.id,
        phone_number: this.phone_call.phone_number,
        workspace_id: this.activeWorkspace._id,
      };
      await conversationsAPI.incomingCall(form_data)
        .then(async (response) => {
          await popupErrorMessages(response);
          console.log('incoming call get data');
          console.log(response.data);
          const get_contact = response.data.contact;
          console.log(response.data.contact);
          this.phone_call.name = get_contact.name === get_contact.phone_number ? 'Unkwown Contact' : get_contact.name;
          this.phone_call.phone_number = get_contact.phone_number;
          this.phone_call.conversation_id = response.data.convo.id;
          this.audio_ringtone.loop = true;
          this.audio_ringtone.play();
          this.showModalMinimizeCall = true;
        }).catch((err) => {
          console.log(err);
          this.audio_ringtone.pause();
          this.handleEndPhoneCall();
        });
    },
    async handleAnswerIncomingCall() {
      const options = {
        extraHeaders: ['X-Foo: foo', 'X-Bar: bar'],
        mediaConstraints: {
          audio: true,
          video: false,
        },
        rtcOfferConstraints: {
          offerToReceiveAudio: true,
        },
        mandatory: [
          {
            OfferToReceiveAudio: true,
            OfferToReceiveVideo: false,
          },
          {
            DtlsSrtpKeyAgreement: true,
          },
        ],
      };
      if (this.call_session.session) {
        this.call_session.session.answer(options);
        this.call_session.session.isOnHold = false;
      }
      await this.soundIncomingCall.pause();
      this.soundIncomingCall.currentTime = 0;
      if (this.sdp_offer) {
        if (!this.pc) {
          await this.rtcSetupShared();
        }
        this.current_call_id = this.call_message_data.msg_id;
        const session = this.sdp_offer;
        await this.setupAudio();
        await this.pc.setRemoteDescription(session);
        console.log('WebRTC: Setting remote description: ', session);

        // For the client create an answer
        const answer = await this.pc.createAnswer();
        console.log('LOCAL_ANSWER created: ', answer);
        this.showLoader();
        const response = await conversationsAPI.callAction({
          workspace_id: this.activeWorkspace._id,
          conversation_id: this.call_message_data.conversation_id,
          action: 'accept',
          call_id: this.call_message_data.msg_id,
          sdp_answer: {
            sdp: answer.sdp,
            sdp_type: 'answer',
          },
        });
        this.hideLoader();
        console.log('response.error', response.error);
        if (response.error) { // reset call state
          return;
        }
        this.pc.setLocalDescription(answer);
        // if (this.sdp_offer.type === 'offer') {
        //   this.call = {
        //     duration: 0,
        //     startTime: moment.utc(),
        //   };
        // } else {
        //   return;
        // }
      }
      this.call.startTime = moment.utc();
      this.call.talktime = setInterval(() => {
        if (this.call.startTime) {
          const now = moment.utc();
          const duration = moment.duration(now.diff(this.call.startTime, 'second'));
          if (duration > 3600) {
            this.call.duration = moment.utc(duration * 1000).format('HH:mm:ss');
          } else {
            this.call.duration = moment.utc(duration * 1000).format('mm:ss');
          }
          if ((duration % 60) === 0) {
            const total_duration = duration / 60;
            console.log('total duration', total_duration);
            this.socket.emit('call-per-minute', { call_id: this.call_message_data.msg_id, total_duration });
            conversationsAPI.pingCall({
              call_id: this.call_message_data.msg_id,
              total_duration,
            }).then((responsePing) => {
              if (!responsePing.error) {
                this.successPingCall.push(total_duration);
              }
            });
          }
        }
      }, 1000);
    },
    async endCall(res) {
      this.phone_call.status = 'Ended';
      const form_data = {
        call_id: this.call_session.session.id,
        phone_number: this.phone_call.phone_number,
        workspace_id: this.activeWorkspace._id,
        conversation_id: this.phone_call.conversation_id,
        status: res.status,
        reason: res.cause,
        time: {
          progress: this.call.startCall,
          handling: this.call.startTime,
          end: moment.utc(),
        },
      };
      clearInterval(this.call.talktime);
      await conversationsAPI.endCall(form_data)
        .then(async (response) => {
          await popupErrorMessages(response);
          this.handleEndPhoneCall();
        }).catch((err) => {
          console.log(err);
          this.handleEndPhoneCall();
        });
    },
    async rtcSetupShared() {
      const servers = {
        iceServers: [
          {
            url: 'stun:stun.l.google.com:19302',
          },
        ],
      };
      this.pc = new RTCPeerConnection(servers);
      console.log('WebRTC: Created RTCPeerConnection');
      /**
       * Hooking up the RTCPeerConnection events
       */
      this.pc.onicegatheringstatechange = (ev) => {
        const connection = ev.target;

        console.log('WebRTC: Event onicegatheringstatechange:', connection.iceGatheringState);
      };

      this.pc.onicecandidate = (event) => {
        console.log('WebRTC: Event onicecandidate:', event);

        if (event && event.candidate) {
          this.localIceCandidates.add(event.candidate);
        }

        console.log('Updated LOCAL_DESCRIPTION:', this.pc ? this.pc.localDescription : null);
      };

      this.pc.onicecandidateerror = (event) => {
        console.log('WebRTC: Event onicecandidateerror:', event);
      };

      this.pc.onconnectionstatechange = () => {
        console.log('WebRTC: Event onconnectionstatechange:', this.pc ? this.pc.connectionState : null);
      };

      this.pc.oniceconnectionstatechange = (ev) => {
        console.log('WebRTC: Event oniceconnectionstatechange:', ev);
      };

      this.pc.onnegotiationneeded = (ev) => {
        console.log('WebRTC: Event onnegotiationneeded:', ev);
      };

      this.pc.onsignalingstatechange = (ev) => {
        console.log('WebRTC: Event onsignalingstatechange:', ev);
      };

      this.pc.ondatachannel = (ev) => {
        console.log('WebRTC: Event ondatachannel:', ev.channel);
      };

      this.pc.ontrack = (event) => {
        console.log('WebRTC: Event ontrack: Received remote streams %d. Hooking up track ID: %s', event.streams.length, event.streams[0].id);
        const audio = document.querySelector('audio#audio');
        audio.srcObject = event.streams[0];
        event.streams[0].getTracks().forEach((track) => {
          if (track) {
            this.remoteStream.addTrack(track); // Add each track to the remote stream
          }
        });
        this.startRecording();
      };
    },
    startRecording() {
      const audioContext = new AudioContext();
      console.log('Set local source as audio context stream');
      const localSource = audioContext.createMediaStreamSource(this.localStream);
      console.log('Set remote source as audio context stream');
      const remoteSource = audioContext.createMediaStreamSource(this.remoteStream);
      const destination = audioContext.createMediaStreamDestination();
      localSource.connect(destination);
      remoteSource.connect(destination);
      this.mediaRecorder = new MediaRecorder(destination.stream);
      // Clear previous audio chunks
      this.audioChunks = [];

      // On dataavailable, push the audio chunks to the array
      this.mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          this.audioChunks.push(event.data);
          const uploadData = {
            workspace_id: this.activeWorkspace._id,
            predefined_record_id: this.sdp_offer.predefined_record_id,
            chunk: event.data, // Actual audio chunk
          };
          this.socket.emit('uploadData', uploadData);
        }
      };

      // Start recording
      this.mediaRecorder.start(1000);
    },
    /**
     * Get the local Mic and add it to peer connection.
     */
    async setupAudio() {
      if (this.localStream) {
        return;
      }
      /**
       * Setup Media
       */
      this.localStream = await navigator.mediaDevices.getUserMedia({
        video: false,
        audio: true,
      });
      const audioTracks = this.localStream.getAudioTracks();
      console.log('Found total %d Local Audio Tracks', audioTracks.length);
      if (audioTracks.length > 0) {
        console.log(`Using Audio device: ${audioTracks[0].label}`);
      }

      console.log('Adding Local Stream to peer connection');
      this.localStream.getTracks().forEach((track) => {
        console.log(`Added track: ${track.label} (ID: ${track.id}) to peer connection`);
        this.pc.addTrack(track, this.localStream);
      });

      // setCodecPreferences();
      // setupGraphs();
    },
    handleMicPhoneCall() {
      // console.log('[before] trigger mute call', this.isPhoneMuted);
      this.isPhoneMuted = !this.isPhoneMuted;
      // console.log('trigger mute call', this.isPhoneMuted);
      this.localStream.getAudioTracks().forEach((track) => {
        track.enabled = !this.isPhoneMuted;
      });
    },
    async handleEndPhoneCall() {
      console.log('Handler end call');
      await this.soundIncomingCall.pause();
      this.soundIncomingCall.currentTime = 0;
      await this.$store.dispatch('ui/clearActiveCall');
      if (this.SIPsocket) this.SIPsocket.terminateSessions();
      if (this.call_message_data && this.call_message_data.call_session) {
        // console.log(this.call_message_data.conversation_id, this.call_message_data.msg_id);
        conversationsAPI.callAction({
          workspace_id: this.activeWorkspace._id,
          conversation_id: this.call_message_data.conversation_id,
          action: !this.call.duration && this.call_direction === 'incoming' ? 'reject' : 'terminate',
          call_id: this.call_message_data.msg_id,
          duration: this.call.duration,
        });
      }
      if (this.localStream) {
        console.log('Reset local stream', this.sdp_offer);
        if (this.mediaRecorder) {
          this.mediaRecorder.stop();

          // Combine the chunks into a Blob after recording stops
          const { predefined_record_id } = this.sdp_offer;
          // console.log('predefined_record_id', predefined_record_id);
          this.mediaRecorder.onstop = () => {
            console.log('Recording stop');
            // const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
            // const audioURL = URL.createObjectURL(audioBlob); // Create a URL for the recorded audio
            // Object.assign(document.createElement('a'), { href: audioURL, download: 'record.webm' }).click();
            const data = {
              workspace_id: this.activeWorkspace._id,
              predefined_record_id,
            };
            this.socket.emit('endOfStream', data);
          };
        }
        this.localStream.getTracks().forEach((track) => {
          console.log(`Stopping track: ${track.label} (ID: ${track.id}) to peer connection`);
          track.stop();
        });
      }
      this.current_call_id = null;
      this.localStream = null;
      this.sdp_offer = {};
      this.call_message_data = {};
      this.pc = null;
      clearInterval(this.call.talktime);
      this.call_session = {};
      this.showModalIncomingCall = false;
      this.showModalMinimizeCall = false;
      this.showModalMaximizeCall = false;
      this.call = {
        duration: 0,
        startTime: '',
        talktime: 0,
        startCall: '',
      };
      this.phone_call = {
        status: 'Trying...',
        mic: 'mdi mdi-microphone mdi-24px',
        volume: 'mdi mdi-volume-low mdi-24px',
        name: '',
        phone_number: '',
        conversation_id: '',
      };
    },
    env(key) {
      return process.env[key];
    },
    handlerBtnSetAvailibility() {
      const message = `Set your availibility status to ${this.is_agent_availibility ? 'offline' : 'online'}?`;
      this.$confirm(message, this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;

            const response = await agents.setMyAgentStatus({
              status: this.is_agent_availibility ? 'off' : 'on',
            })
              .catch(() => {});
            await popupErrorMessages(response);
            this.is_agent_availibility = !this.is_agent_availibility;
            this.agent_availibility_color = this.is_agent_availibility ? 'success' : 'danger';
            this.$message({
              message: 'Your availibility status has been changed',
              type: 'success',
            });
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      }).catch(() => {});
    },
    async checkAvailibility() {
      const response = await agents.myAgentStatus();
      if (!response.error) {
        this.agent_availibility_color = isEmpty(response.data.agent_availibility.find((v) => v.is_available)) ? 'danger' : 'success';
        this.is_agent_availibility = !isEmpty(response.data.agent_availibility.find((v) => v.is_available));
      }
    },
    showLoader() {
      // if (this.loaderStack === 0) {
      //   this.loader = this.$loading.show();
      // }
      // this.loaderStack += 1;
      this.loaderStack = 1;
      this.loader = this.$loading.show();
    },
    hideLoader() {
      // this.loaderStack -= 1;
      // if (this.loaderStack === 0) {
      //   this.loader.hide();
      // }
      this.loaderStack = 0;
      this.loader.hide();
    },
    async getBusinessEntities() {
      const response = await profileApi.businessEntities();
      this.business_entities = response.data.rows;
    },
    async getBusinessTypes() {
      const response = await profileApi.businessTypes();
      this.business_types = response.data.rows;
    },
    async changePassword() {
      this.v$.form.$touch();
      if (this.v$.form.$error) return;
      this.loading.change_password = true;
      const response = await authApi.change_password({
        old_password: this.form.old_password,
        new_password: this.form.new_password,
        confirm_password: this.form.confirm_password,
      }).catch(() => {});
      this.loading.change_password = false;
      await popupErrorMessages(response);
      this.$message({
        type: this.$t('success'),
        message: this.$t('auth.success.change_password'),
      });
      this.showModal = false;
    },
    closeModal() {
      this.showModal = false;
      this.form = {};
    },
    selectWorkspace() {
      this.$store.dispatch('workspace/clearActiveWorkspace')
        .then(() => {
          this.$router.push('/workspaces');
        });
    },
    async doLogout() {
      const loader = this.$loading.show();
      await authApi.logout();
      loader.hide();
      this.$store.dispatch('auth/doLogout').then(() => {
        this.$router.push('/auth/login');
      });
    },
    async doShowModalCompanyProfile() {
      this.showLoader();
      this.showModalCompanyProfile = true;
      this.v$.company_form.$touch();
      await this.loadCompanyProfile();
      this.company_form = clone(this.companyProfile);
      await this.getBusinessEntities();
      await this.getBusinessTypes();
      this.hideLoader();
    },
    async loadCompanyProfile() {
      const response = await profileApi.companyProfile();
      this.companyProfile = response.data.profile;
    },
    async updateCompanyProfile() {
      this.v$.company_form.$touch();
      if (this.v$.company_form.$error) return;
      this.loading.update_compro = true;
      const formData = new FormData();
      formData.append('name', this.company_form.name);
      formData.append('brand_name', this.company_form.brand_name);
      formData.append('address', this.company_form.address);
      formData.append('business_entity_id', this.company_form.business_entity_id);
      formData.append('business_type_id', this.company_form.business_type_id);
      if (this.company_form.file) {
        formData.append('file', this.company_form.file);
      }
      const response = await profileApi.updateCompanyProfile({
        data: formData,
      });
      this.loading.update_compro = false;
      await popupErrorMessages(response);
      this.$store.dispatch('auth/updateCompanyProfile', response.data.profile);
      this.$message({
        type: this.$t('success'),
        message: this.$t('general.success'),
      });
      this.showModalCompanyProfile = false;
    },
    closeModalCompanyProfile() {
      this.showModalCompanyProfile = false;
    },
    async checkWorkspaceBalance() {
      if (this.activeWorkspace._id) {
        this.$store.dispatch('workspace/setWorkspaceBalance', { balance: 0 });
        const response = await workspaceApi.check_balance({
          id: this.activeWorkspace._id,
        }).catch(() => {});
        // await popupErrorMessages(response);
        this.$store.dispatch('workspace/setWorkspaceBalance', response.error ? { balance: 0 } : response.data);
      }
    },
    async doShowModalAccountProfile() {
      this.showLoader();
      this.showModalAccountProfile = true;
      this.v$.account_form.$touch();
      await this.loadAccountProfile();
      this.account_form = clone(this.accountProfile);
      this.hideLoader();
    },
    async loadAccountProfile() {
      const response = await profileApi.accountProfile();
      this.accountProfile = response.data.profile;
    },
    async updateAccountProfile() {
      this.v$.account_form.$touch();
      if (this.v$.account_form.$error) return;
      this.loading.update_accpro = true;
      const formData = new FormData();
      formData.append('name', this.account_form.name);
      formData.append('phone_number', this.account_form.phone_number);
      if (this.account_form.password) {
        formData.append('password', this.account_form.password);
      }
      formData.append('email', this.account_form.email);
      if (this.account_form.file) {
        formData.append('file', this.account_form.file);
      }
      const response = await profileApi.updateAccountProfile({
        data: formData,
      });
      this.loading.update_accpro = false;
      await popupErrorMessages(response);
      this.$store.dispatch('auth/updateAccountProfile', response.data.profile);
      this.$message({
        type: this.$t('success'),
        message: this.$t('general.success'),
      });
      this.showModalAccountProfile = false;
    },
    async uploadAttachment() {
      if (!this.account_form.file) {
        return;
      }
      this.loading.upload_file = true;
      const formData = new FormData();
      formData.append('file', this.account_form.file);
      const response = await profileApi.uploadAttachment({
        data: formData,
      }, (progressEvent) => {
        const p = parseInt((progressEvent.loaded / progressEvent.total) * 100, 0);
        this.upload_progress = p;
        if (p === 100) {
          this.upload_status = 'success';
        }
      });
      popupErrorMessages(response);
      if (this.account_form.attachment_ids) this.account_form.attachment_ids.push(response.data.attachment.id);
      else this.account_form.attachment_ids = [response.data.attachment.id];
      this.loading.upload_file = false;
    },
    closeModalAccountProfile() {
      this.showModalAccountProfile = false;
    },
    handlerChangeAmountBtn() {
      this.form1.amount = this.amount_opt.find((v) => v.text === this.form1.topup_amount).value;
      // console.log(value);
    },
    async handlerBtnNextTopup() {
      if (this.current_step === 0) {
        const pm = this.sof.find((v) => v.id === this.form1.payment_method_id);
        if (typeof this.form1.amount === 'string') {
          /* eslint-disable radix */
          this.form1.amount = parseInt(this.form1.amount);
        }
        await this.calculateTopup();
        this.confirmation_detail = {
          topup_amount: `Rp. ${this.calculate_topup_result.topup_amount.toLocaleString()}`,
          payment_method: pm.name,
          admin_fee: `Rp. ${this.calculate_topup_result.admin_fee.toLocaleString()}`,
          ppn_amount: this.calculate_topup_result.ppn_amount ? `Rp. ${this.calculate_topup_result.ppn_amount.toLocaleString()}` : '',
          total_transfer: `Rp. ${this.calculate_topup_result.grand_total.toLocaleString()}`,
        };
      } else if (this.current_step === 1) {
        await this.requestTopup();
      }
      if (this.current_step === 2) {
        this.showModalTopup = false;
        return;
      }
      this.current_step += 1;
    },
    async topupHistories() {
      this.loading.balance_histories = true;
      const { data } = await topupApi.topupHistories({
        id: this.activeWorkspace._id,
      });
      if (data.count) {
        this.topup_histories_list = data.rows.map((v) => {
          v.topup_date = moment(v.created_at).format('DD MMM YYYY');
          v.topup_amount_str = `Rp.${v.amount.toLocaleString()}`;
          v.tag_type = 'warning';
          if (v.status === 'paid') {
            v.tag_type = 'success';
          } else if (['expired', 'failed'].includes(v.status)) {
            v.tag_type = 'danger';
          } else if (v.status === 'cancelled') {
            v.tag_type = 'info';
          }
          return v;
        });
      }
      this.loading.balance_histories = false;
    },
    async requestTopup() {
      this.showLoader();
      try {
        const response = await topupApi.createRequest({
          id: this.activeWorkspace._id,
          data: {
            amount: this.form1.amount,
            payment_method_id: this.form1.payment_method_id,
          },
        });
        await popupErrorMessages(response);
        const { data: resp } = response;
        this.$message({
          message: this.$t('topup.success.create_request'),
          type: 'success',
        });
        this.resp = resp;
        this.resp.expired_at_str = moment(resp.expired_at).format('DD MMM YYYY, HH:mm');
      } catch (error) {
        this.current_step = 0;
        console.log(error);
      }
      this.hideLoader();
    },
    async handlerBtnTopup() {
      this.showLoader();
      try {
        const { data: resp_inquiry } = await topupApi.topupInquiryLastRequest({
          id: this.activeWorkspace._id,
        });
        console.log(resp_inquiry, isEmpty(resp_inquiry));
        if (!isEmpty(resp_inquiry)) {
          const {
            id, amount, admin_fee, total_transaction, virtual_account_number, expired_at, payment_method_detail,
            invoice_number, ppn_amount,
          } = resp_inquiry.current_request;
          this.confirmation_detail = {
            topup_amount: `Rp. ${amount.toLocaleString()}`,
            ppn_amount: ppn_amount ? `Rp. ${ppn_amount.toLocaleString()}` : '',
            payment_method: payment_method_detail.name,
            admin_fee: `Rp. ${admin_fee.toLocaleString()}`,
            total_transfer: `Rp. ${(total_transaction).toLocaleString()}`,
          };
          this.resp = {
            id,
            virtual_account_number,
            invoice_number,
            expired_at_str: moment(expired_at).format('DD MMM YYYY, HH:mm'),
            how_to_pay: payment_method_detail.how_to_pay,
          };
          this.is_waiting_payment = true;
          this.current_step = 2;
          this.hideLoader();
          this.showModalTopup = true;
          return;
        }
        this.showModalTopup = true;
        this.v$.form1.$touch();
        const { data } = await topupApi.paymentMethodList();
        this.sof = data.sof;
      } catch (error) {
        console.log(error);
        popupErrorMessages(error);
      }
      this.hideLoader();
    },
    onHideTopup() {
      this.form = {
        topup_amount: '',
        amount: null,
        payment_method_id: null,
      };
      this.confirmation_detail = {};
      this.resp = {
        how_to_pay: [],
      };
      this.current_step = 0;
      this.is_waiting_payment = false;
      this.showModalTopup = false;
    },
    handlerBtnCancelRequest() {
      this.$confirm(this.$t('topup.confirmation_cancel_request'), this.$t('general.confirmation'), {
        type: 'warning',
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            // cb();
            // this.showLoader();
            instance.confirmButtonLoading = true;
            await topupApi.cancelRequest({
              id: this.activeWorkspace._id,
              topup_id: this.resp.id,
            }).then(async (response) => {
              await popupErrorMessages(response);
              this.$message({
                message: this.$t('topup.success.cancel_request'),
                type: 'success',
              });
              this.onHideTopup();
            });
            instance.confirmButtonLoading = false;
          }
          cb();
        },
      });
    },
    async calculateTopup() {
      const pm = this.sof.find((v) => v.id === this.form1.payment_method_id);
      const response = await topupApi.calculateTopup({
        workspace_id: this.activeWorkspace._id,
        data: {
          payment_method_id: pm.id,
          amount: this.form1.amount,
        },
      });
      this.calculate_topup_result = response.data;
    },
    async handlerBtnTopupDetail(item) {
      this.showLoader();
      this.showModalTopupDetail = true;
      await topupApi.detailRequest({
        id: this.activeWorkspace._id,
        topup_id: item.id,
      }).then(async (response) => {
        await popupErrorMessages(response);
        const { data } = response;
        data.result.topup_amount_str = `Rp.${data.result.amount.toLocaleString()}`;
        data.result.admin_fee_str = `Rp.${data.result.admin_fee.toLocaleString()}`;
        if (data.result.ppn_amount) data.result.ppn_amount_str = `Rp.${data.result.ppn_amount.toLocaleString()}`;
        data.result.total_transaction_str = `Rp.${data.result.total_transaction.toLocaleString()}`;
        data.result.payment_method_str = data.result.payment_method_detail.name;
        data.result.paid_at_str = data.result.status === 'paid' ? moment(data.result.paid_at).format('DD MMM YYYY, HH:mm') : '-';
        data.result.tag_type = 'warning';
        if (data.result.status === 'paid') {
          data.result.tag_type = 'success';
        } else if (['expired', 'failed'].includes(data.result.status)) {
          data.result.tag_type = 'danger';
        } else if (data.result.status === 'cancelled') {
          data.result.tag_type = 'info';
        }
        data.result.status_str = data.result.status.replace(/_/g, ' ').toUpperCase();
        data.result.expired_at_str = moment(data.result.expired_at).format('DD MMM YYYY, HH:mm');
        this.topup_history_detail = data.result;
      });
      this.hideLoader();
    },
    // handleStatusIncomingCall(status) {
    //   this.phone_call.status = status;
    // },
    // handleIncomingMaximizeCall(sess) {
    //   this.call_session = sess;
    //   this.showModalIncomingCall = true;
    //   this.showModalMinimizeCall = false;
    // },
    // handleIncomingMinimizeCall(sess) {
    //   this.call_session = sess;
    //   this.showModalMinimizeCall = true;
    //   this.showModalIncomingCall = false;
    // },
    handleMaximizePhoneCall() {
      this.showModalMaximizeCall = true;
      this.showModalMinimizeCall = false;
    },
    handleMinimizePhoneCall() {
      this.showModalMinimizeCall = true;
      this.showModalMaximizeCall = false;
    },
  },
};
</script>
