<template>
  <el-dialog
    title="Add New Leads"
    :visible.sync="showModal"
    width="50%">
    <el-form label-width="150px" @reset="onClose" class="full-width mb-5">
      <el-form-item label="Lead Name">
        <el-input v-model="form.name" placeholder="Please enter project name"></el-input>
      </el-form-item>
      <el-form-item label="Assign to">
        <el-select v-model="form.sales_id" filterable remote reserve-keyword :remote-method="searchUser"
        placeholder="Please enter a keyword" :loading="loading.get_users" style="width: 100%">
          <el-option
            v-for="item in filter_users"
            :key="item.id"
            :label="item.text"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Projection Amount">
        <el-input v-model="form.amount" placeholder="Please enter project amount (optional)"
        :formatter="(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
        :parser="(value) => value.replace(/\Rp\s?|(,*)/g, '')"></el-input>
      </el-form-item>
      <el-form-item label="Due Date">
        <el-date-picker
          v-model="form.due_date"
          type="date"
          width="100%"
          placeholder="Pick date (optional)"
          style="width: 100%"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item class="float-right">
        <el-button size="small" type="primary" @click="createLeads" :loading="loading.submit">Submit</el-button>
        <el-button size="small" @click="onClose">Cancel</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import popupErrorMessages from '@/library/popup-error-messages';
import leadsAPI from '../../../api/leads';
import usersAPI from '../../../api/users';

export default {
  name: 'CreateLeads',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selected_contact: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loaderStack: 0,
      loader: null,
      showModal: false,
      loading: {
        get_users: false,
        submit: false,
      },
      filter_users: [],
      form: {
        name: '',
        amount: '',
        due_date: '',
        contact_id: '',
        sales_id: '',
      },
    };
  },
  watch: {
    show() {
      this.showModal = this.show;
      if (this.show) {
        this.loadUsers();
        console.log(this.selected_contact);
        this.form.contact_id = this.selected_contact.id;
      }
    },
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  methods: {
    onClose() {
      this.$emit('onClose');
    },
    onSubmit() {
      this.$emit('onSubmit');
    },
    loadUsers() {
      this.searchUser();
    },
    async searchUser(keyword = '') {
      this.filter_users = [];
      this.loading.get_users = true;
      await usersAPI.getList(1, keyword).then((res) => {
        this.filter_users = res.data.rows.map((v) => {
          const x = {
            id: v.id,
            text: `${v.name} (${v.email})`,
          };
          return x;
        });
      }).catch((err) => {
        console.log(err);
      });
      this.loading.get_users = false;
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    createLeads() {
      if (this.form.contact_id) {
        this.loading.submit = true;
        this.showLoader();
        leadsAPI.create_leads({
          workspace_id: this.activeWorkspace._id,
          data: this.form,
        }).then(async (res) => {
          this.hideLoader();
          this.loading.submit = false;
          await popupErrorMessages(res);
          this.$message({
            message: this.$t('leads.success.lead.add'),
            type: 'success',
          });
          this.onSubmit();
        }).catch(async () => {
          this.hideLoader();
          this.loading.submit = false;
        });
      } else {
        this.$message({
          message: this.$t('leads.failed.lead.add'),
          type: 'error',
        });
        this.onClose();
      }
    },
  },
};
</script>
